<script>
import { Mixin } from '@/mixins/util.js'
import { Table, TableColumn, Button } from 'element-ui'
export default {
  name: 'inputlist',
  mixins: [Mixin],
  components: {
    'el-table': Table,
    'el-table-column': TableColumn,
    'el-button': Button
  },
  data() {
    return {
      table_78: [],
      colConfigs: [
        { prop: '0', label: '日付', width: '110' },
        { prop: '1', label: '貿易ID', width: '120' },
        { prop: '2', label: '商品ID', width: '110' },
        { prop: '3', label: '商品名', width: '280' },
        { prop: '4', label: '輸入単価', width: '100' },
        { prop: '5', label: '個数', width: '100' },
        { prop: '6', label: '營業税率', width: '100' },
        { prop: '7', label: '営業税額', width: '110' },
        { prop: '8', label: '商品あたり営業税単價', width: '100' },
        { prop: '9', label: '精算済個数', width: '110' },
        { prop: '10', label: '精算済金額', width: '110' },
        { prop: '11', label: '差分', width: '95' },
        { prop: '12', label: '備考', width: '95' }
      ]
    }
  },
  computed: {
    TotableData() {
      return this.table_78
        .map((items, index) => {
          let temp = items.map((item, idx) => {
            if (!isNaN(item * 1) && idx != 1) return (item * 1).toLocaleString()
            return item
          })

          let temp1 = { ...temp }
          temp1[0] = temp1[0].split(' ')[0]
          temp1['7'] = this.total_money[index].toLocaleString()
          temp1['8'] = this.unitPrice[index].toLocaleString()
          return temp1
        })
        .sort((a, b) => new Date(a[0]) - new Date(b[0]))
    },
    contentData() {
      const data = this.TotableData.map(item => {
        const data = Object.entries(item)
        return data.map(t => t[1].replace(/,/g, ''))
      })
      return data
    },
    header() {
      const title = this.colConfigs.map(item => Object.entries(item)[1][1])
      return [title]
    },
    excelData() {
      return [[...this.header, ...this.contentData]]
    },
    total_money() {
      return this.table_78.map(
        items => items[4] * 1 * (items[5] * 1) * (items[6] * 1)
      )
    },
    unitPrice() {
      return this.table_78.map(
        (items, index) => this.total_money[index] / (items[5] * 1)
      )
    }
  },
  methods: {
    dowexcel() {
      this.dwnExcel(this.excelData, '輸入一覽', ['輸入一覽'], [])
    },
    getChartDataWithTimeOut(y, m) {
      const result = this.fetchWithTimeout(y, m, '78')
      this.$eventBus.$emit('loadingHandler', true)
      result
        .then(res => res.text())
        .then(rep => {
          rep = JSON.parse(this.RemoveNonValidJSONChars(rep))
          this.$eventBus.$emit('loadingHandler', false)
          // console.log(rep)
          if (rep.errno !== '0') {
            this.$eventBus.$emit('logOutHandler', true)
            this.ToLoginPage()
          } else {
            const keys = Object.keys(rep).filter(item => !isNaN(item))
            keys.forEach(
              key =>
                (this[`table_${key}`] = rep[key].map(item => {
                  item[1] = this.Mosaic(
                    item[1],
                    sessionStorage.getItem('session_id')
                  )
                  item[2] = this.Mosaic(
                    item[2],
                    sessionStorage.getItem('session_id')
                  )
                  item[3] = this.Mosaic(
                    item[3],
                    sessionStorage.getItem('session_id')
                  )
                  return item
                }))
            )
          }
        })
        .catch(() => {
          this.$eventBus.$emit('loadingHandler', false)
          this.$eventBus.$emit('serverDownHandler', true)
          this.ToLoginPage()
        })
    },
    ToLoginPage() {
      this.timer = setTimeout(() => {
        this.$router.push({
          name: 'login',
          params: { t: '123' }
        })
      }, 2500)
    },
    tableAlign({ columnIndex }) {
      //console.log(columnIndex)
      if (columnIndex > 3) return 'text-align:right'
    }
  },
  created() {
    // this.getData(new Date().getFullYear(), new Date().getMonth() + 1)
    this.getChartDataWithTimeOut(
      new Date().getFullYear(),
      new Date().getMonth() + 1
    )
  },
  mounted() {
    const menu = document.querySelector('.menu')
    const ham = document.querySelector('.ham')
    menu.style.left = '-320px'
    ham.style.display = 'block'
  },
  beforeDestroy() {
    clearTimeout(this.timer)
  }
}
</script>

<template src="./template.html"></template>
<style lang="scss" src="./style.scss" scoped></style>
